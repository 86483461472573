// const requireRoute = require.context('.', false, /\.js$/) // Get js files inside routes folder
import auth from "./auth";
import main from "./main";
import products from "./products";
import listings from "./listings";
import dashboard from "./dashboard";
import orders from "./orders";
import settings from "./settings";

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("@/layouts/main/Main.vue"),
    children: [...main, ...products,...listings, ...dashboard, ...orders],
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: "",
    component: () => import("@/layouts/full-page/FullPage.vue"),

    children: [...auth],
  },
  // =============================================================================
  // SETTINGS
  // =============================================================================
  {
    path: "",
    component: () => import("@/layouts/settings/Settings.vue"),
    children: [...settings],
  },
];

export default routes;
