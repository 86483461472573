import axios from "axios";

export default {
  getOrders(params) {
    return axios.get("/orders",{params});
  },
  getOrder(id) {
    return axios.get(`/orders/${id}`);
  },
  editNotes(params) {
    return axios.post(`/orders/${params.id}/notes`, params.params);
  },
};
