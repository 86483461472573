/* eslint-disable */
import * as types from "@/store/mutation-types";
import api from "@/services/api/products";
import moment from "moment";

const getters = {
  table_page: (state) => state.table_page,
  edit_product: (state) => state.edit_product,
  images: (state) => state.images,
  // images: (state) => {
  //   return state.images.filter((i) => !i.deleted_image);
  // },
  productEditTabs: (state) => state.productEditTabs,
  products: (state) => state.products,
  categories: (state) => state.categories,
  conditions: (state) => state.conditions,
  identifiers: (state) => state.identifiers,
  add_product_info: (state) => state.add_product_info,
  linkedProducts: (state) => state.linkedProducts,
  variation_table_headers: (state) => state.variation_table_headers,
  custom_attributes: (state) => {
    let result = [];
    if (state.selected_attributes.length) {
      const mapSelected = state.selected_attributes.map((attr) => {
        return attr.name;
      });
      result = state.custom_attributes.filter(
        (o) => !mapSelected.includes(o.name),
      );
    } else {
      result = state.custom_attributes;
    }
    return result;
  },
  selected_products: (state) => {
    const selected_products = state.products.data.filter((p) => p.selected);
    return selected_products;
  },
  selectedProductId: (state) => {
    const selected_products = state.products.data.filter((p) => p.selected);
    const selectedProductsId = selected_products.map((item) => item.id);
    return selectedProductsId;
  },
  products_table_headers: (state) => state.productsTableHeaders,
  selected_attributes: (state) => state.selected_attributes,
  variations: (state) => state.variations,
  warehouseLocation: (state) => state.warehouseLocation,
  selectedLocation: (state) => {
    return state.warehouseLocation.filter((l) => l.selected);
  },
  productTags: (state) => state.productTags,
  productTsHistory: (state) => state.productTsHistory,
  product_tags: (state) => state.tags,
};

const actions = {
  getProducts({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getProducts(params)
        .then((response) => {
          const products = response.data.data;
          commit(types.SET_PRODUCTS, products);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProductTsHistory({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getProductTsHistory(params)
        .then((response) => {
          const tsHistory = response.data.data;
          commit(types.SET_TS_HISTORY, tsHistory);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getProduct(payload)
        .then((response) => {
          commit(types.SET_PRODUCT, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLinkedProducts({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getLinkedProducts(id)
        .then((response) => {
          commit(types.SET_LINKED_PRODUCTS, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTags({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getTags()
        .then((response) => {
          commit(types.SET_TAGS, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .addProduct(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editProductinfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editProductinformation(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editProductInfoListing({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editProductListingsinformation(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editProductInfoPricingRules({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editProductInfoPricingRules(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editProductInfoQuantityRules({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editProductInfoQuantityRules(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addProductTags({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .attachTagToProduct(payload)
        .then((response) => {
          commit(types.SET_PRODUCT_TAG, response.data.tags);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTagFromProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteTagFromProduct(payload)
        .then((response) => {
          commit(types.DELETE_PRODUCT_TAG, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadProductImages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .uploadProductImages(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createVariations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createVariations(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addCategoryToProducts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .attachCategoryToProducts(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCategories(payload)
        .then((response) => {
          commit(types.SET_CATEGORIES, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAttributes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCustomAttributes(payload)
        .then((response) => {
          commit(types.SET_ATTRIBUTES, response.data);
          commit(types.ADD_PRODUCT_EDIT_CUSTOM_ATTRIBUTES, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteProducts({ commit }, payload) {
    let formData = new FormData();
    payload.forEach((p) => {
      formData.append("ids[]", p);
    });
    return new Promise((resolve, reject) => {
      api
        .deleteProducts(formData)
        .then((response) => {
          commit(types.DELETE_PRODUCTS, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [types.SET_PRODUCTS](state, products) {
    let filterProds = products.data.map((p) => {
      p.selected = false;
      p.created_at = moment(p.created_at).format("MMMM Do, YYYY");
      return p;
    });
    state.products = products;
    state.products.data = filterProds;
  },
  [types.SET_TS_HISTORY](state, info) {
    state.productTsHistory = info;
  },
  [types.SET_PRODUCT_INFO](state, product_info) {
    state.add_product_info = product_info;
  },
  [types.SET_EDIT_PRODUCT_DESC](state, product) {
    state.edit_product = { ...state.edit_product, ...product };
  },

  [types.SET_LINKED_PRODUCTS](state, products) {
    state.linkedProducts = products;
  },
  [types.SET_PRODUCT](state, product_info) {
    const nullKeysWhiteList = ["parent_id"];
    const product = product_info;

    for (const productKey in product) {
      if (
        product[productKey] === null &&
        !nullKeysWhiteList.includes(productKey)
      )
        delete product[productKey];
    }

    product.category = product.category ? product.category.name : "";
    if (product.package_weight == null) {
      product.package_weight = {};
    }
    let tab_var = state.productEditTabs.find((t) => t.key === "variations");
    let tab_inventory = state.productEditTabs.find(
      (t) => t.key === "inventory",
    );

    if (product.parent_id) {
      tab_inventory.disabled = false;
      tab_var.disabled = true;
    } else if (product.variants.length) {
      tab_var.disabled = false;
      tab_inventory.disabled = true;
    } else {
      tab_var.disabled = false;
      tab_inventory.disabled = false;
    }

    state.edit_product = product;

    if (state.edit_product.features) {
      state.edit_product.features = JSON.parse(
        JSON.stringify(state.edit_product.features),
      );
    } else {
      state.edit_product.features = [];
    }

    if (
      !state.selected_attributes.length &&
      state.edit_product.custom_attributes &&
      state.edit_product.custom_attributes.length
    ) {
      state.edit_product.custom_attributes.forEach((attr) => {
        state.selected_attributes.push({
          id: attr.id,
          name: attr.name,
          value: attr.pivot.value,
        });
      });
    }
  },
  [types.ADD_ATTRIBUTE](state, attribute) {
    state.selected_attributes.push(attribute);
  },
  [types.DELETE_ATTRIBUTE](state, attribute) {
    let index = 0;
    if (attribute.id) {
      index = state.selected_attributes.findIndex(
        (a) => +a.id === +attribute.id,
      );
    } else {
      index = state.selected_attributes.findIndex(
        (a) => a.name === attribute.name,
      );
    }
    state.selected_attributes.splice(index, 1);
  },

  [types.SET_PRODUCT_CHECKED](state, { event, value }) {
    let find = state.products.data.find((a) => a.id == event.id);
    if (find) {
      find.selected = value;
    }
  },
  [types.SET_ALL_PRODUCT_CHECKED](state, { event }) {
    state.products.data.map((p) => {
      p.selected = event;
    });
  },
  [types.ADD_NEW_VARIATION](state, new_variation) {
    state.variations.push(new_variation);
  },
  [types.DELETE_VARIATION](state, del_variation) {
    if (del_variation.id) {
      const index = state.edit_product.variants.findIndex(
        (v) => v.id === del_variation.id,
      );
      state.edit_product.variants.splice(index, 1);
    }
  },
  [types.ADD_NEW_VARIATION](state, new_variation) {
    state.variations.push(new_variation);
  },
  [types.SET_CATEGORIES](state, categories) {
    categories.map((c) => {
      c.text = c.name;
      return c;
    });
    state.categories = [...state.categories, ...categories];
  },
  [types.DELETE_PRODUCTS](state, ids) {
    ids.forEach((id) => {
      const index = state.products.data.findIndex((a) => +a.id === +id);
      state.products.data.splice(index, 1);
    });
  },
  [types.SET_IMAGES](state, images) {
    state.edit_product.images = images;
  },
  [types.APPEND_IMAGE](state, image) {
    state.edit_product.images.push(image);
  },
  [types.DELETE_IMAGE](state, img) {
    let images = [...state.edit_product.images];
    state.edit_product.images = [];
    if (img.id) {
      let image = images.find((i) => i.id == img.id);
      image.deleted = true;
    } else {
      const index = images.findIndex((i) => i.name === img.name);
      images.splice(index, 1);
    }
    state.edit_product.images = images;
  },
  [types.SET_LOCATION_VALUE](state, { location, value }) {
    const index = state.warehouseLocation.findIndex(
      (l) => l.id === location.id,
    );
    state.warehouseLocation[index].selected = value;
  },
  [types.SET_CATEGORY](state, category) {
    state.categories.push({ text: category.name, key: category.name });
  },
  [types.ADD_PRODUCT_EDIT_CUSTOM_ATTRIBUTES](state, attribute) {
    state.custom_attributes = attribute;
  },
  [types.SET_PRODUCT_TAG](state, tags) {
    if (tags.length) {
      for (let i = 0; i < tags.length; i++) {
        state.edit_product.tags.push(tags[i]);
      }
    }
  },
  [types.DELETE_PRODUCT_TAG](state, tag) {
    const index = state.edit_product.tags.findIndex((t) => t.id === tag.tag_id);
    state.edit_product.tags.splice(index, 1);
  },
  [types.SET_TAGS](state, tags) {
    state.tags = tags;
  },
  [types.EMPTY_PRODUCT_INFORMATION](state) {
    state.edit_product = {
      category: "",
      package_weight: {},
      features: [],
      sku: null,
    };
    state.selected_attributes = [];
  },
};

const state = {
  edit_product: {
    category: "",
    package_weight: {},
    features: [],
    sku: null,
  },
  products: {
    data: [],
  },
  images: [],
  add_product_info: {},
  categories: [],
  conditions: [
    { id: "New", text: "New" },
    { id: "Used", text: "Used" },
    { id: "Reconditioned", text: "Reconditioned" },
  ],
  identifiers: [
    { key: "asin", text: "ASIN" },
    { key: "upc", text: "UPC" },
    { key: "gtin", text: "GTIN" },
    { key: "ean", text: "EAN" },
    { key: "epid", text: "EPID" },
    { key: "gcid", text: "GCID" },
    { key: "isbn", text: "ISBN" },
  ],
  variation_table_headers: [
    {
      label: "SKU",
      key: "sku",
      required: true,
    },
    {
      label: "Battery Included",
      key: "battery_included",
    },
    {
      label: "Weight",
      key: "weight",
    },
  ],
  custom_attributes: [],
  selected_attributes: [],
  table_page: [
    { id: 10, text: "10" },
    { id: 15, text: "15" },
    { id: 20, text: "20" },
    { id: 30, text: "30" },
    { id: 50, text: "50" },
    { id: 100, text: "100" },
  ],
  productsTableHeaders: [
    { label: "#", key: "id" },
    { label: "Image", key: "image" },
    { label: "Name", key: "name" },
    { label: "SKU", key: "sku" },
    { label: "Category", key: "category" },
    { label: "Total Available", key: "quantity" },
    { label: "Reserved", key: "reserved" },
    { label: "Price", key: "price" },
    { label: "Last Modified", key: "created_at" },
    { label: "Draft Listings", key: "draft_listings" },
    { label: "Active Listings", key: "active_listings" },
  ],

  variations: [
    {
      id: 1,
      sku: "test-1",
      color: "Red",
      material: "Glass",
      price: 3000,
      available: 0,
      reserved: 0,
      qty: 0,
    },
  ],
  deleted_images: [],
  productEditTabs: [
    {
      label: "Product Info",
      key: "product-info",
    },
    {
      label: "Description",
      key: "description",
    },
    {
      label: "Images",
      key: "images",
    },
    {
      label: "Inventory",
      key: "inventory",
      disabled: false,
    },
    {
      label: "Variations",
      key: "variations",
      disabled: false,
    },
    {
      label: "Custom Attributes",
      key: "custom-attributes",
    },
    {
      label: "Listings",
      key: "listings",
    },
    {
      label: "Pricing Rules",
      key: "pricing-rules",
    },
    {
      label: "Quantity Rules",
      key: "quantity-rules",
    },
    {
      label: "Orders",
      key: "orders",
    },
  ],
  warehouseLocation: [
    {
      id: 1,
      name: "Warehouse",
      data: [
        { key: "quantity", name: "Available", value: 1, disabled: true },
        { key: "reserved", name: "Reserved", value: 0 },
        { key: "quantity_on_hand", name: "On Hand", value: 2 },
        // { key: "price", name: "On Hand", value: 1 },
        // { key: "cost", name: "Cost", value: 312 },
        // { key: "bin_location", name: "Bin Location", value: 21321 },
      ],
      selected: true,
      disabled: true,
    },
    {
      id: 2,
      name: "Yerevan",
      data: [
        { key: "available", name: "Available", value: 1 },
        { key: "reserved", name: "Reserved", value: 0 },
        { key: "price", name: "On Hand", value: 1 },
        { key: "cost", name: "Cost", value: 0 },
        { key: "bin_location", name: "Bin Location", value: 0 },
      ],
      selected: false,
    },
  ],
  productTags: [],
  productTsHistory: {},
  tags: [],
  linkedProducts: {},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
