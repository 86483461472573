export default [
  {
    path: "/settings/account_info",
    name: "settings",
    meta: {
      title: "Account Info"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "account_info" */ "@/views/Settings/components/SettingsAccoutInfo.vue"
        )
  },
  {
    path: "/settings/import_data",
    name: "Import Data",
    meta: {
      title: "Import Data"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/SettingsImportDataPage"
        )
  },
  {
    path: "/settings/import_data/product_csv",
    name: "product_csv",
    meta: {
      title: "Import Products"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/import-data/ProductCSV"
        )
  },
  {
    path: "/settings/import_data/inventory_csv",
    name: "inventory_csv",
    meta: {
      title: "Import Inventory"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/import-data/InventoryCSV"
        )
  },
  {
    path: "/settings/import_data/ebay",
    name: "settings",
    meta: {
      title: "Import Listings from eBay"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/import-data/SettingsEbayImportData.vue"
        )
  },
  {
    path: "/settings/import_data/shopify",
    name: "settings",
    meta: {
      title: "Import Listings from Shopify"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/import-data/SettingsShopifyImportData.vue"
        )
  },
  {
    path: "/settings/import_data/walmart",
    name: "settings",
    meta: {
      title: "Import Listings from Walmart"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/import-data/SettingsWalmartImportData.vue"
        )
  },
  {
    path: "/settings/import_data/backmarket",
    name: "settings",
    meta: {
      title: "Import Listings from Backmarket"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/import-data/SettingsBackmarketImportData.vue"
        )
  },
  {
    path: "/settings/channels",
    name: "channels",
    meta: {
      title: "Your Sales Channel Integrations"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/sales-channel/SettingsSalesChannel.vue"
        )
  },
  {
    path: "/settings/change_password",
    name: "change_password",
    meta: {
      title: "Change Password"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/SettingsChangePassword.vue"
        )
  },
  {
    path: "/settings/users",
    name: "users",
    meta: {
      title: "Users"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/SettingsUsers.vue"
        )
  },
  {
    path: "/settings/payment_info",
    name: "payment_info",
    meta: {
      title: "Payment Info"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/SettingsPaymentInfo.vue"
        )
  },
  {
    path: "/settings/channels/shopify/new",
    name: "settings",
    meta: {
      title: "Connect a New Sales Channel"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/sales-channel/connect-channels/ConnectShopifyChannel.vue"
        )
  },
  {
    path: "/settings/channels/ebay/new",
    name: "settings",
    meta: {
      title: "Connect a New Sales Channel"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/sales-channel/connect-channels/ConnectEbayChannel.vue"
        )
  },
  {
    path: "/settings/product_categories",
    name: "settings",
    meta: {
      title: "Product Categories"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/SettingsProductCategories.vue"
        )
  },
  {
    path: "/settings/custom_product_attributes",
    name: "settings",
    meta: {
      title: "Custom Product Attributes"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/SettingsProductAttributes.vue"
        )
  },
  {
    path: "/settings/channels/ebay/add_key",
    name: "settings",
    meta: {
      title: "Channel settings"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/sales-channel/AddKeyEbay.vue"
        )
  },
  {
    path: "/settings/channels/shopify/add_key",
    name: "settings",
    meta: {
      title: "Channel settings"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/sales-channel/AddKeyShopify.vue"
        )
  },
  {
    path: "/settings/channels/backmarket/add_key",
    name: "settings",
    meta: {
      title: "Channel settings"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/sales-channel/AddKeyBackmarket.vue"
        )
  },
  {
    path: "/settings/channels/walmart/add_key",
    name: "settings",
    meta: {
      title: "Channel settings"
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/Settings/components/sales-channel/AddKeyWalmart.vue"
        )
  }
];
