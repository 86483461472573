import axios from "axios";

export default {
  addProduct(payload) {
    return axios.post("/products", payload);
  },
  getProductTsHistory({id,params}) {
    return axios.get(`/transaction/${id}`, {params});
  },
  getProducts(params) {
    return axios.get("/products", { params });
  },
  getLinkedProducts(id) {
    return axios.get(`/products/linked-listings/${id}`);
  },
  editProductinformation({ id, payload }) {
    return axios.post(`/products/${id}`, payload);
  },
  editProductListingsinformation({ id, payload }) {
    return axios.post(`/products/${id}/manual-listing-rule`, payload);
  },
  editProductInfoPricingRules({ id, payload }) {
    return axios.post(`/products/${id}/product-pricing-rule`, payload);
  },
  editProductInfoQuantityRules({ id, payload }) {
    return axios.post(`/products/${id}/product-quantity-rule`, payload);
  },
  getTags() {
    return axios.get("/tags");
  },
  attachTagToProduct(payload) {
    return axios.post("/tags/associate", payload);
  },
  createVariations({ id, payload }) {
    return axios.post(`/products/variation/${id}`, payload);
  },
  getCustomAttributes() {
    return axios.get("/custom-attributes");
  },
  getProduct(id) {
    return axios.get(`/products/${id}`);
  },
  getCategories() {
    return axios.get("/categories");
  },
  attachCategoryToProducts(payload) {
    return axios.post("/categories/categorize", payload);
  },
  deleteProducts(payload) {
    return axios.post("/products/delete", payload);
  },
  deleteTagFromProduct(payload) {
    return axios.post("/tags/disassociate", payload);
  },
  uploadProductImages(payload) {
    return axios({
      method: "post",
      url: `/products/image/upload`,
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
