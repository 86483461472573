import axios from "axios";

export default {
  userLogin(payload) {
    return axios.post("/auth/login", payload);
  },
  userLogout(){
    return axios.post("/auth/logout");
  },
  refreshToken() {
    return axios.post("/auth/refreshAccess");
  },
  ebayOauth(){
    return axios.get('/ebay/oauth')
  }
};
