import international_services from "./international_services";

const shipment_types = [
  { key: "Flat", text: "Flat: same cost to all buyers" },
  {
    key: "Calculated",
    text: "Calculated: cost varies by buyer location",
  },
  { key: "FREIGHT", text: "Freight: large items over 150 lbs" },
  { key: "LOCAL", text: "Local Pickup" },
];

const intern_shipment_types = [
  { key: "Flat", text: "Flat: same cost to all buyers" },
  {
    key: "Calculated",
    text: "Calculated: cost varies by buyer location",
  },
];

const shipping_packages = [
  { key: "letter", text: "Letter" },
  { key: "large_envelope", text: "Large envelope" },
  { key: "package", text: "Package" },
  { key: "large_package", text: "Large package" },
];

const services = [
  { key: "1", text: "Some service 1" },
  { key: "2", text: "Some service 2" },
  { key: "3", text: "Some service 3" },
];

const ebayListingEditTabs = [
  {
    label: "Product Identifiers",
    key: "product-identifiers",
  },
  {
    label: "Title & Description",
    key: "title-description",
  },
  {
    label: "Images",
    key: "images",
  },
  {
    label: "Variations",
    key: "variations",
  },
  {
    label: "Category & Format",
    key: "category-format",
  },
  {
    label: "Shipping",
    key: "shipping",
  },
  {
    label: "Payments & Returns",
    key: "payments-returns",
  },
];

const backmarketListingEditTabs = [
  {
    label: "Title & Comment",
    key: "title-comment",
  },
  {
    label: "Price & Quantity",
    key: "price-quantity",
  },
];
const walmartListingEditTabs = [
  {
    label: "Vital Info",
    key: "vital-info",
  },
  {
    label: "Title & Description",
    key: "title-description",
  },
  {
    label: "Offer",
    key: "offer",
  },
  {
    label: "Promotions",
    key: "promotions",
  },
  {
    label: "Images",
    key: "images",
  },
  {
    label: "Category",
    key: "category",
  },
  {
    label: "Shipping",
    key: "shipping",
  },
  {
    label: "Options",
    key: "options",
  },
];
const handling_times = [
  {
    groupName: "Extended",
    groupItems: [
      { key: "4", text: "4 days" },
      { key: "5", text: "5 days" },
      { key: "10", text: "10 days" },
      { key: "15", text: "15 day" },
      { key: "20", text: "20 days" },
      { key: "30", text: "30 days" },
    ],
  },
];

const ship_locations = [
  { key: " N. and S. America", text: " N. and S. America" },
  { key: "CN", text: "Canada" },
  { key: "Mexico", text: "Mexico" },
  { key: "Brazil", text: "Brazil" },
  { key: "Russian Federation", text: "Russian Federation" },
  { key: "Europe", text: "Europe" },
  { key: "United Kingdom", text: "United Kingdom" },
  { key: "Germany", text: "Germany" },
  { key: "France", text: "France" },
  { key: "Asia", text: "Asia" },
  { key: "Australia", text: "Australia" },
  { key: "Japan", text: "Japan" },
  { key: "China", text: "China" },
];

const ship_to_options = [
  { key: "CA", text: "Canada" },
  { key: "Worldwide", text: "Worldwide" },
  { key: "choose", text: "Choose custom location" },
];

const buyers_requirements = {
  have_received: [
    { key: "2", text: "2" },
    { key: "3", text: "3" },
    { key: "4", text: "4" },
    { key: "5", text: "5" },
  ],
  months: [
    { key: "1", text: "1" },
    { key: "6", text: "6" },
    { key: "12", text: "12" },
  ],
  limit: [
    { key: "1", text: "1" },
    { key: "2", text: "2" },
    { key: "3", text: "3" },
    { key: "4", text: "4" },
    { key: "5", text: "5" },
    { key: "6", text: "6" },
    { key: "7", text: "7" },
    { key: "8", text: "8" },
    { key: "9", text: "9" },
    { key: "10", text: "10" },
    { key: "25", text: "25" },
    { key: "50", text: "50" },
    { key: "75", text: "75" },
    { key: "100", text: "100" },
  ],
  block_buyers: [
    { key: "0", text: "0" },
    { key: "1", text: "1" },
    { key: "2", text: "2" },
    { key: "3", text: "3" },
    { key: "4", text: "4" },
    { key: "5", text: "5" },
  ],
};

const returns_accepted = {
  within: [
    { key: "Days_14", text: "14 Days" },
    { key: "Days_30", text: "30 Days" },
    { key: "Days_60", text: "60 Days" },
  ],
  refund: [
    { key: "MoneyBack", text: "Money back" },
    {
      key: "MoneyBackOrReplacement",
      text: "Money back or replacement (buyers choise)",
    },
    {
      key: "MoneyBackOrExchange",
      text: "Money back or exchange (buyers choise)",
    },
  ],
  paid_by: [
    { key: "Buyer", text: "Buyer" },
    { key: "Seller", text: "Seller" },
  ],
};

export {
  shipment_types,
  shipping_packages,
  services,
  ebayListingEditTabs,
  handling_times,
  intern_shipment_types,
  ship_locations,
  ship_to_options,
  buyers_requirements,
  returns_accepted,
  international_services,
  walmartListingEditTabs,
  backmarketListingEditTabs,
};
