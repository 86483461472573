<template>
  <div class="spinner"></div>
</template>

<script>
export default {};
</script>

<style>
.spinner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%,#accf29);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 6.4px),#000 0);
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
}
</style>