/* eslint-disable */ 
export default [
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: {
        title: "Dashboard"
        // requiresAuth: true,
      },
      component: () =>
        import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard.vue')
    }
  ]
  