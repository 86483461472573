import axios from "axios";
import router from "@/router";
import { store } from "@/store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let refreshToken = localStorage.getItem("refresh_token");
    console.log(error)
    if (error && error.response.status === 401 && refreshToken) {
      console.log(error)
      await axios
          .post("/auth/refresh", { refresh_token: refreshToken })
          .then((res) => {
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            router.go();
          })
          .catch(() => {
              localStorage.removeItem("token");
              localStorage.removeItem("refresh_token");
              store.commit("SAVE_USER", "");
              router.push("/login");
          });
      // store.commit('CLEAR_AUTH');

      // if (router.history.current.path.includes("profile")) {
      //     router.push("/auth/login").catch(() => {})
      // }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {

    const whiteList = [
      "/auth/login",
      "/auth/refresh"
    ];
    if (localStorage.getItem("token")) {
      if (
        whiteList.indexOf(config.url) === -1 &&
        localStorage.getItem("token")
      ) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);

    // Do something with response error
  }
);
