import axios from "axios";

export default {
  addCustomAttributes(payload) {
    return axios.post("/custom-attributes", payload);
  },
  getCustomAttributes() {
    return axios.get("/custom-attributes");
  },
  editAttribute({ id, name }) {
    return axios.post(`/custom-attributes/${id}`, { name });
  },
  removeAttribute(id) {
    return axios.delete(`/custom-attributes/${id}`);
  },

  addCategory(payload) {
    return axios.post("/categories", payload);
  },
  getCategories() {
    return axios.get("/categories");
  },
  editCategory({ id, name }) {
    return axios.post(`/categories/${id}`, { name });
  },
  removeCategory(id) {
    return axios.delete(`/categories/${id}`);
  },
  importProductsCSV(file){
    return axios({
      method: "post",
      url: `/import/products`,
      data: file,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  importInventoryCSV(file){
    return axios({
      method: "post",
      url: `/import/inventory`,
      data: file,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateCredentials({ id,params }) {
    return axios.post(`/channels/${id}`, params);
  },
  getCredentials(id) {
    return axios.get(`/channels/${id}`);
  },
  getAccountInfo() {
    return axios.get('/me');
  },
  updateAccountInfo(params) {
    return axios.post('/me', params);
  }
};
