/* eslint-disable */
import * as types from "@/store/mutation-types";
import router from "@/router";
import api from "@/services/api/auth";

const getters = {
  user: (state) => state.user
};

const actions = {
  userLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // commit(types.SHOW_LOADING, true)
      api
        .userLogin(payload)
        .then((response) => {
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          commit(types.SAVE_USER, response.data.access_token);
          router.push("/");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  userLogout({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .userLogout()
        .then((response) => {
          localStorage.removeItem("token", response.data.access_token);
          localStorage.removeItem("refresh_token", response.data.refresh_token);
          commit(types.SAVE_USER, "");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshToken({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .refreshToken()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  ebayOauth({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .ebayOauth()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  [types.SAVE_USER](state, user) {
    state.user = user;
  }
};

const state = {
  user: localStorage.getItem("token") || null
};

export default {
  state,
  getters,
  actions,
  mutations
};
