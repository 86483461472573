<template>
  <button
    class="flex items-center justify-center outline-none   border border-solid "
    :click="className"
    :class="{ 'disabled_btn': disabled}"
    :disabled="disabled"
    @click="$emit('submit')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style scoped></style>
