/* eslint-disable */
import * as types from "@/store/mutation-types";
import api from "@/services/api/orders";

const getters = {
  orders: (state) => state.orders,
  order: (state) => state.order,
};

const actions = {
  getOrders({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getOrders(params)
        .then((response) => {
          commit(types.SET_ORDERS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrder({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getOrder(id)
        .then((response) => {
          commit(types.SET_ORDER, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editNotes({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .editNotes(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [types.SET_ORDERS](state, orders) {
    state.orders = orders;
  },
  [types.SET_ORDER](state, order) {
    state.order = order;
  },
};

const state = {
  orders: [],
  order: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
