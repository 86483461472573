export default [
  {
    path: "/products",
    name: "products",
    meta: {
      title: "Products",
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/Products.vue"
      ),
  },
  {
    path: "/add-product",
    name: "add-product",
    meta: {
      title: "Add Product",

      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "add-product" */ "@/views/products/components/AddProduct.vue"
      ),
  },
  {
    path: "/edit-product/:id",
    name: "edit-product/:id",
    meta: {
      title: "Edit Product",
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "add-product" */ "@/views/products/components/EditProduct.vue"
      ),
  },
];
