// locale
export const SET_LOCALE = "SET_LOCALE";
export const SAVE_USER = "SAVE_USER";

//products
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT_INFO = "SET_PRODUCT_INFO";
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const SET_PRODUCT_CHECKED = "SET_PRODUCT_CHECKED";
export const SET_ALL_PRODUCT_CHECKED = "SET_ALL_PRODUCT_CHECKED";
export const ADD_NEW_VARIATION = "ADD_NEW_VARIATION";
export const DELETE_VARIATION = "DELETE_VARIATION";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_TAGS = "SET_TAGS";
export const EMPTY_PRODUCT_INFORMATION = "EMPTY_PRODUCT_INFORMATION";
export const SET_PRODUCT = "SET_PRODUCT";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const SET_IMAGES = "SET_IMAGES";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const APPEND_IMAGE = "APPEND_IMAGE";
export const ADD_CATEGORY_IN_PRODUCT_CATEGORIES =
  "ADD_CATEGORY_IN_PRODUCT_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_ATTRIBUTE_IN_PRODUCT_ATTRIBUTE =
  "ADD_ATTRIBUTE_IN_PRODUCT_ATTRIBUTE";
export const ADD_PRODUCT_EDIT_CUSTOM_ATTRIBUTES =
  "ADD_PRODUCT_EDIT_CUSTOM_ATTRIBUTES";
export const SET_PRODUCT_TAG = "SET_PRODUCT_TAG";
export const DELETE_PRODUCT_TAG = "DELETE_PRODUCT_TAG";
export const SET_EDIT_PRODUCT_DESC = "SET_EDIT_PRODUCT_DESC";
export const SET_LINKED_PRODUCTS = "SET_LINKED_PRODUCTS";
export const SET_TS_HISTORY = "SET_TS_HISTORY";

// listings
export const SET_SHOPIFY_LISTINGS = "SET_SHOPIFY_LISTINGS";
export const SET_WALMART_LISTINGS = "SET_WALMART_LISTINGS";
export const SET_BACKMARKET_LISTINGS = "SET_BACKMARKET_LISTINGS";
export const SET_LISTING_CHECKED = "SET_LISTING_CHECKED";
export const SET_ALL_LISTING_CHECKED = "SET_ALL_LISTING_CHECKED";
export const DELETE_ATTRIBUTE_FROM_PRODUCT_ATTRIBUTE =
  "DELETE_ATTRIBUTE_FROM_PRODUCT_ATTRIBUTE";
export const SET_LOCATION_VALUE = "SET_LOCATION_VALUE";
export const DELETE_LISTINGS = "DELETE_LISTINGS";
export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const SET_EDIT_SHOPIFY_LISTINGS = "SET_EDIT_SHOPIFY_LISTINGS";
export const SET_SHOPIFY_LISTING_PARAMS = "SET_SHOPIFY_LISTING_PARAMS";
export const SET_EDIT_EBAY_LISTINGS = "SET_EDIT_EBAY_LISTINGS";
export const SET_EDIT_EBAY_LOCATION = "SET_EDIT_EBAY_LOCATION";
export const SET_EDIT_EBAY_TAX_INFO = "SET_EDIT_EBAY_TAX_INFO";
export const SET_EBAY_BROWSE_CATEGORIES = "SET_EBAY_BROWSE_CATEGORIES";
export const SET_EDIT_WALMART_LISTINGS = "SET_EDIT_WALMART_LISTINGS";
export const CLEAR_SHOPIFY_LISTINGS = "CLEAR_SHOPIFY_LISTINGS";
export const SET_EBAY_LISTINGS = "SET_EBAY_LISTINGS";
export const SET_ALL_EBAY_LISTING_CHECKED = "SET_ALL_EBAY_LISTING_CHECKED";
export const SET_ALL_WALMART_LISTING_CHECKED =
  "SET_ALL_WALMART_LISTING_CHECKED";
export const SET_ALL_BACKMARKET_LISTING_CHECKED =
  "SET_ALL_BACKMARKET_LISTING_CHECKED";
export const SET_EBAY_LISTING_CHECKED = "SET_EBAY_LISTING_CHECKED";
export const SET_SUGG_CATEGORIES = "SET_SUGG_CATEGORIES";
export const SET_ASPECTS = "SET_ASPECTS";
export const SET_EDIT_TABS = "SET_EDIT_TABS";
export const SET_EBAY_CONDITIONS = "SET_EBAY_CONDITIONS";
export const SET_EBAY_LISTING_FORMAT = "SET_EBAY_LISTING_FORMAT";
export const SET_EBAY_LISTING_IMMEDIATE_PAYMENT =
  "SET_EBAY_LISTING_IMMEDIATE_PAYMENT";
export const SET_EBAY_TITLE_AND_DESCRIPTION = "SET_EBAY_TITLE_AND_DESCRIPTION";
export const SET_EBAY_CATEGORY_AND_FORMAT = "SET_EBAY_CATEGORY_AND_FORMAT";
export const SET_EBAY_PRICING_AND_QUANTITY = "SET_EBAY_PRICING_AND_QUANTITY";
export const SET_EBAY_DOMESTIC_SHIPPING = "SET_EBAY_DOMESTIC_SHIPPING";
export const SET_EBAY_PACKAGE_DETAILS = "SET_EBAY_PACKAGE_DETAILS";
export const SET_EBAY_HANDLING_TIME = "SET_EBAY_HANDLING_TIME";
export const SET_EBAY_EXCLUDE_LOCATIONS = "SET_EBAY_EXCLUDE_LOCATIONS";
export const SET_EBAY_SCHEDULED_TIME = "SET_EBAY_SCHEDULED_TIME";
export const SET_EBAY_INTERNATIONAL_SHIPPING =
  "SET_EBAY_INTERNATIONAL_SHIPPING";
export const SET_EBAY_GLOBAL_SHIPPING = "SET_EBAY_GLOBAL_SHIPPING";
export const SET_EBAY_RETURN_POLICY = "SET_EBAY_RETURN_POLICY";
export const SET_EBAY_PRODUCT_IDENTIFIERS = "SET_EBAY_PRODUCT_IDENTIFIERS";
export const SET_EDIT_EBAY_CONDITIONS = "SET_EDIT_EBAY_CONDITIONS";
export const SET_EBAY_PRODUCT_VARIANTS = "SET_EBAY_PRODUCT_VARIANTS";
export const SET_WALMART_LISTING_CHECKED = "SET_WALMART_LISTING_CHECKED";
export const SET_BACKMARKET_LISTING_CHECKED = "SET_BACKMARKET_LISTING_CHECKED";
export const SET_EDIT_BACKMARKET_LISTINGS = "SET_EDIT_BACKMARKET_LISTINGS";

//settings
export const ADD_CUSTOM_ATTRIBUTES = "ADD_CUSTOM_ATTRIBUTES";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const DELETE_CUSTOM_ATTRIBUTE = "DELETE_CUSTOM_ATTRIBUTE";
export const SET_CATEGORIESS = "SET_CATEGORIESS";

// orders
export const SET_ORDERS = "SET_ORDERS";
export const SET_ORDER = "SET_ORDER";
