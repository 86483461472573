/* eslint-disable */ 
export default [
    {
      path: '/orders',
      name: 'orders',
      meta: {
        title: "Orders"
        // requiresAuth: true,
      },
      component: () =>
        import(/* webpackChunkName: "orders" */ '@/views/orders/Orders.vue')
    },
    {
      path: '/orders/:id',
      name: 'view_orders',
      meta: {
        title: "Order"
        // requiresAuth: true,
      },
      component: () =>
        import(/* webpackChunkName: "orders" */ '@/views/orders/pages/ViewOrder')
    }
  ]
  