import axios from "axios";

export default {
  getShopifyListings(params) {
    return axios.get("/listings/shopify", { params });
  },
  editShopifyListing({ payload, id }) {
    return axios.post(`/listings/shopify/${id}`, payload);
  },
  getShopifyListing(id) {
    return axios.get(`/listings/shopify/${id}`);
  },
  getWalmartListing(id) {
    return axios.get(`/listings/walmart/${id}`);
  },
  getBackmarketListing(id) {
    return axios.get(`/backmarket/listings/${id}`);
  },
  getEbayListings(params) {
    return axios.get("/listings/ebay", { params });
  },
  getWalmartListings(params) {
    return axios.get("/listings/walmart", { params });
  },
  getBackmarketListings(params) {
    return axios.get("/backmarket/listings", { params });
  },
  searchForLink(params) {
    return axios.get("/products/for-link", { params });
  },
  linkToProduct({ listingId, product_id }) {
    return axios.post(`/products/shopify/link/${listingId}`, { product_id });
  },
  linkToProductEbay({ listingId, product_id }) {
    return axios.post(`/products/ebay/link/${listingId}`, { product_id });
  },
  linkToProductWalmart({ listingId, product_id }) {
    return axios.post(`/listings/walmart/link/${listingId}`, { product_id });
  },
  linkToProductBackmarket({ listingId, product_id }) {
    return axios.post(`/backmarket/listings/link/${listingId}`, { product_id });
  },
  linkOnProductChannel(payload) {
    return axios.post(`/listings/shopify/list-product`, payload);
  },
  unlinkFromProduct(listingId) {
    return axios.post(`/products/shopify/unlink/${listingId}`);
  },
  unlinkFromProductEbay(listingId) {
    return axios.post(`/products/ebay/unlink/${listingId}`);
  },
  unlinkFromProductWalmart(listingId) {
    return axios.post(`/lisings/walmart/unlink/${listingId}`);
  },
  unlinkFromProductBackmarket(listingId) {
    return axios.post(`/backmarket/listings/unlink/${listingId}`);
  },
  listOnShopify(id) {
    return axios.post(`/listings/shopify/list-product/${id}`);
  },
  getCollections() {
    return axios.get("/shopify/collections");
  },
  updateProducts({ id, payload }) {
    return axios.post(`/products/shopify/${id}`, payload);
  },
  deleteListing(ids) {
    return axios.post(`/shopify/listings/delete`, ids);
  },
  deleteEbayListings(ids) {
    return axios.delete(`/listings/ebay`, { data: ids });
  },
  deleteEbayListing(id) {
    return axios.delete(`/listings/ebay/${id}`);
  },
  publishListing(id) {
    return axios.post(`/shopify/listings/publish/${id}`);
  },
  backmarketPublishListing(id) {
    return axios.post(`/backmarket/listings/publish/${id}`);
  },
  ebayPublishListing(id) {
    return axios.post(`/listings/ebay/publish/${id}`);
  },
  bulkPublishShopify(payload) {
    return axios.post(`/shopify/listings/publish`, payload);
  },
  syncListing(id) {
    return axios.get(`/shopify/listings/sync/${id}`);
  },
  shopifyListingImport(payload) {
    return axios.post(`/shopify/listings/import`, payload);
  },
  syncCollections() {
    return axios.get(`/shopify/collections/sync`);
  },
  getEbayListing(id) {
    return axios.get(`/listings/ebay/${id}`);
  },
  listOnProductChannelEbay(id) {
    return axios.post(`/listings/ebay/list-product/${id}`);
  },
  listOnProductChannelBulkEbay(payload) {
    return axios.post(`/listings/ebay/list-product`, payload);
  },
  searchEbayCategories(params) {
    return axios.get(`/ebay/category/search`, { params });
  },
  aspectsEbayCategories(params) {
    return axios.get(`/ebay/category/aspects`, { params });
  },
  getEbayConditions(params) {
    return axios.get(`/ebay/policies/marketplace/condition`, { params });
  },
  updateEbayListing(id, payload) {
    return axios.post(`/listings/ebay/${id}`, payload);
  },
  importEbayListing(params) {
    return axios.get(`/ebay/listings/import`, { params });
  },
  updateWalmartListing({ id, payload }) {
    return axios.post(`/listings/walmart/${id}`, payload);
  },
  updateBackmarketListing({ id, payload }) {
    return axios.post(`/backmarket/listings/${id}`, payload);
  },
  publishWalmartListing(id) {
    return axios.post(`/listings/walmart/publish/${id}`);
  },
  syncWalmartListing(id) {
    return axios.get(`/listings/walmart/sync/${id}`);
  },
  importListingFromWalmart(params) {
    return axios.get(`/listings/walmart/import`, { params });
  },
  deleteWalmartListing(ids) {
    return axios.post(`/listings/walmart/delete`, ids);
  },
  importListingFromBackmarket(params) {
    return axios.get(`/backmarket/listings/import`, { params });
  },
  deleteBackmarketListing(ids) {
    return axios.post(`/backmarket/listings/delete`, ids);
  },
  syncListingFromBackmarket(id) {
    return axios.post(`/backmarket/listings/sync/${id}`);
  },
  getBrowseCategories(query) {
    return axios.get("/ebay/category/browse", { params: query });
  },
  syncEbayListing(id) {
    return axios.get(`/listings/ebay/sync/${id}`);
  },
  endEbayListing({ id, reason }) {
    return axios.get(`/listings/ebay/end/${id}`, { params: { reason } });
  },
  syncEbayAllListings() {
    return axios.get(`/listings/ebay/sync`);
  },
  changeEbayChannelActiveStatus(payload) {
    return axios.post(`/channels/3/status`, payload);
  },
  changeShopifyChannelActiveStatus(payload) {
    return axios.post(`/channels/1/status`, payload);
  },
  changeBackmarketChannelActiveStatus(payload) {
    return axios.post(`/channels/4/status`, payload);
  },
};
