export default [
  {
    path: "/listings/shopify",
    name: "listings/shopify",
    meta: {
      title: "Shopify Listings"
      // requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "ShopifyListings" */ "@/views/listings/ShopifyListings.vue"),
  },
  {
    path: "/listings/ebay",
    name: "listings/ebay",
    meta: {
      title: "Ebay Listings"
      // requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "EbayListings" */ "@/views/listings/EbayListings.vue"),
  },
  {
    path: "/listings/walmart",
    name: "listings/Walmart",
    meta: {
      title: "Walmart Listings"
      // requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "Walmart Listings" */ "@/views/listings/WalmartListing.vue"),
  },
  {
    path: "/listings/backmarket",
    name: "listings/backmarket",
    meta: {
      title: "Backmarket Listings"
      // requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "Backmarket Listings" */ "@/views/listings/BackmarketListing.vue"),
  },
  {
    path: "/edit-listings/shopify/:id",
    name: "edit-listings/shopify/:id",
    meta: {
      title: "Edit Listing Shopify",
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-listing-shopify" */ "@/views/listings/ShopifyEditListing.vue"
        ),
  },
  {
    path: "/edit-listings/ebay/:id",
    name: "edit-listings/ebay/:id",
    meta: {
      title: "Edit Listing Ebay",
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-listing-ebay" */ "@/views/listings/EbayEditListings.vue"
        ),
  },
  {
    path: "/edit-listings/walmart/:id",
    name: "edit-listings/walmart/:id",
    meta: {
      title: "Edit Listing Walmart",
      // requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-listing-ebay" */ "@/views/listings/WalmartEditListings.vue"
        ),
  },
  {
      path: "/edit-listings/walmart/:id",
      name: "edit-listings/walmart/:id",
      meta: {
          title: "Edit Listing Walmart",
          // requiresAuth: true,
      },
      component: () =>
      import(
              /* webpackChunkName: "edit-listing-ebay" */ "@/views/listings/WalmartEditListings.vue"
              ),
  },
  {
    path: "/edit-listings/backmarket/:id",
    name: "/edit-listings/backmarket/:id",
    meta: {
      title: "Edit Listing Backmarket"
      // requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "Backmarket Listings" */ "@/views/listings/BackmarketEditListing.vue"),
  },
];