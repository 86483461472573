/* eslint-disable */
import * as types from "@/store/mutation-types";
import api from "@/services/api/listings";
import {
  backmarketListingEditTabs,
  buyers_requirements,
  // domostic_services,
  ebayListingEditTabs,
  handling_times,
  intern_shipment_types,
  international_services,
  returns_accepted,
  services,
  ship_locations,
  ship_to_options,
  shipment_types,
  shipping_packages,
  walmartListingEditTabs,
} from "@/helpers/listings_utils";

const getters = {
  shopifyListings: (state) => state.shopifyListings,
  ebayListings: (state) => state.ebayListings,
  walmartListings: (state) => state.walmartListings,
  ebayListingEditTabs: () => ebayListingEditTabs,
  backmarketListingEditTabs: () => backmarketListingEditTabs,
  walmartListingEditTabs: () => walmartListingEditTabs,
  ebay_edit_data: (state) => state.ebay_edit_data,
  selected_listings: (state) => {
    const selected_listings = state.shopifyListings.data.filter(
      (p) => p.selected,
    );
    const selected_lising_Id = selected_listings.map((item) => item.id);
    return selected_lising_Id;
  },
  selected_ebay_listings: (state) => {
    if (state.ebayListings.data) {
      const selected_listings = state.ebayListings.data.filter(
        (p) => p.selected,
      );
      const selected_lising_Id = selected_listings?.map((item) => item.id);
      return selected_lising_Id;
    } else {
      return [];
    }
  },
  selected_walmart_listings: (state) => {
    if (state.walmartListings.data) {
      const selected_listings = state.walmartListings.data?.filter(
        (p) => p.selected,
      );
      const selected_lising_Id = selected_listings?.map((item) => item.id);
      return selected_lising_Id;
    } else {
      return [];
    }
  },
  selected_backmarket_listings: (state) => {
    if (state.backmarketListings.data) {
      const selected_listings = state.backmarketListings.data?.filter(
        (p) => p.selected,
      );
      const selected_lising_Id = selected_listings?.map((item) => item.id);
      return selected_lising_Id;
    } else {
      return [];
    }
  },
  handling_times: () => handling_times,
  services: () => services,
  shipping_packages: () => shipping_packages,
  shipment_types: () => shipment_types,
  intern_shipment_types: () => intern_shipment_types,
  ship_locations: () => ship_locations,
  ship_to_options: () => ship_to_options,
  buyers_requirements: () => buyers_requirements,
  returns_accepted: () => returns_accepted,
  // domostic_services: () => domostic_services,
  international_services: () => international_services,
  product_identifiers: (state) => state.product_identifiers,
  ebay_edit_listing_location: (state) => state.ebay_edit_listing_location,
  identifier_options: (state) => state.identifier_options,
  listing_collections: (state) => state.listing_collections,
  shopifyEditListing: (state) => state.shopifyEditListing,
  shopifyListingParams: (state) => state.shopifyListingParams,
  ebayEditListing: (state) => state.ebayEditListing,
  walmartEditListing: (state) => state.walmartEditListing,
  backmarketListings: (state) => state.backmarketListings,
  backmarketEditListings: (state) => state.backmarketEditListings,
  ebayEditConditions: (state) => state.ebayEditConditions,
  suggested_categories: (state) => state.suggested_categories,
  aspects: (state) => {
    if (!state.aspects || !state.aspects.length) return;
    const data = state.aspects;
    let field = {
      required_fields: [],
      rec_fields: [],
      additional_fields: [],
    };

    for (let i = 0; i < data.length; i++) {
      const fields = data[i];
      const name = fields.localizedAspectName;
      const values =
        fields.aspectValues && fields.aspectValues.length
          ? fields.aspectValues.map((a) => a.localizedValue)
          : [];
      if (fields.aspectConstraint.aspectRequired) {
        field.required_fields.push({
          name,
          values,
          maxItems:
            fields.aspectConstraint.itemToAspectCardinality === "SINGLE"
              ? 1
              : null,
          freeText: fields.aspectConstraint.aspectMode === "FREE_TEXT",
        });
      } else if (fields.aspectConstraint.aspectUsage === "OPTIONAL") {
        field.additional_fields.push({
          name,
          values,
          maxItems:
            fields.aspectConstraint.itemToAspectCardinality === "SINGLE"
              ? 1
              : null,
          freeText: fields.aspectConstraint.aspectMode === "FREE_TEXT",
        });
      } else if (
        !fields.aspectConstraint.aspectRequired &&
        fields.aspectConstraint.aspectUsage === "RECOMMENDED"
      ) {
        field.rec_fields.push({
          name,
          values,
          maxItems:
            fields.aspectConstraint.itemToAspectCardinality === "SINGLE"
              ? 1
              : null,
          freeText: fields.aspectConstraint.aspectMode === "FREE_TEXT",
        });
      }
    }
    return field;
  },
  ebay_listing_conditions: (state) => state.ebay_listing_conditions,
  ebay_listing_format: (state) => state.ebay_listing_format,
  ebay_edit_title_and_description: (state) =>
    state.ebay_edit_title_and_description,
  ebay_edit_category_and_format: (state) => state.ebay_edit_category_and_format,
  ebay_edit_pricing_and_quantity: (state) =>
    state.ebay_edit_pricing_and_quantity,
  ebay_edit_international: (state) => state.ebay_edit_international,
  ebay_edit_domestic_shipping: (state) => state.ebay_edit_domestic_shipping,
  ebay_edit_handling_time: (state) => state.ebay_edit_handling_time,
  ebay_edit_package_details: (state) => state.ebay_edit_package_details,
  ebay_edit_global_shipping: (state) => state.ebay_edit_global_shipping,
  return_policy: (state) => state.return_policy,
  ebay_edit_product_identifiers: (state) => state.ebay_edit_product_identifiers,
  ebay_edit_product_variants: (state) => state.ebay_edit_product_variants,
  ebay_browse_categories: (state) => state.ebay_browse_categories,
};

const actions = {
  getShopifyListings({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getShopifyListings(params)
        .then((response) => {
          commit(types.SET_SHOPIFY_LISTINGS, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editShopifyListing({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editShopifyListing(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getShopifyListing({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getShopifyListing(payload)
        .then((response) => {
          commit(types.SET_EDIT_SHOPIFY_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getWalmartListings({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getWalmartListings(params)
        .then((response) => {
          commit(types.SET_WALMART_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBackmarketListings({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getBackmarketListings(params)
        .then((response) => {
          commit(types.SET_BACKMARKET_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEbayListings({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getEbayListings(params)
        .then((response) => {
          commit(types.SET_EBAY_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  linkOnProductChannel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .linkOnProductChannel(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listOnProductChannelEbay({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .listOnProductChannelEbay(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listOnProductChannelBulkEbay({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .listOnProductChannelBulkEbay(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listOnShopify({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .listOnShopify(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateProducts({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      api
        .updateProducts({ id, payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateWalmartListing({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      api
        .updateWalmartListing({ id, payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateBackmarketListing({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      api
        .updateBackmarketListing({ id, payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEbayListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      const data = {
        category_name: state.ebay_edit_category_and_format.category_name,
        title: state.ebay_edit_title_and_description.title,
        description: state.ebay_edit_title_and_description.description,
        subtitle: state.ebay_edit_title_and_description.subtitle,
        primary_category: +state.ebay_edit_category_and_format.primary_category,
        secondary_category:
          state.ebay_edit_category_and_format.secondary_category,
        condition_id: +state.ebay_edit_category_and_format.condition_id,
        condition_note: state.ebay_edit_category_and_format.condition_note,
        format: state.ebay_edit_category_and_format.format,
        duration: state.ebay_edit_category_and_format.duration,
        item_specifics: state.ebay_edit_category_and_format.item_specifics,
        schedule_time: state.ebay_edit_listing_scheduled_time,
        products: [
          {
            id: state.ebayEditListing.product.id,
            mpn: state.ebay_edit_product_identifiers.mpn,
            isbn: state.ebay_edit_product_identifiers.isbn,
            epid: state.ebay_edit_product_identifiers.epid,
            upc: state.ebay_edit_product_identifiers.upc,
            ean: state.ebay_edit_product_identifiers.ean,
            sku: state.ebay_edit_product_identifiers.sku,
            quantity_available:
              +state.ebay_edit_pricing_and_quantity.quantity_available,
            start_price: +state.ebay_edit_pricing_and_quantity.start_price,
          },
        ],
        return_policy: state.return_policy,
        payment_policy: {
          immediate_payment: state.ebay_edit_listing_immediate_payment,
        },
        location: {
          city_or_state: state.ebay_edit_listing_location.city_or_state,
          country: state.ebay_edit_listing_location.country,
          zip_code: state.ebay_edit_listing_location.zip_code,
        },
        fulfillment_policy: {
          exclude_locations: state.ebay_edit_exclude_locations,
          domestic_shipping: state.ebay_edit_domestic_shipping,
          // global_shipping: state.ebay_edit_global_shipping,
          tax_info: state.ebay_edit_listing_tax_info,
          handling_time: state.ebay_edit_handling_time,
        },
      };

      if (state.ebay_edit_domestic_shipping.ship_type == "Calculated") {
        data.fulfillment_policy.package_details =
          state.ebay_edit_package_details;
      } else {
        data.fulfillment_policy.package_details = null;
      }

      if (state.ebayEditListing?.product?.variants?.length) {
        data.products = state.ebay_edit_product_variants;
      }

      if (!state.ebay_edit_international.disabled) {
        const international_shipping = state.ebay_edit_international;

        delete international_shipping.disabled;

        data.fulfillment_policy.international_shipping = international_shipping;
      } else {
        data.fulfillment_policy.international_shipping = null;
      }

      if (state.ebay_edit_category_and_format.format !== "Auction") {
        data.best_offer =
          state.ebay_edit_pricing_and_quantity.best_offer &&
          (state.ebay_edit_pricing_and_quantity.auto_accept_price ||
            state.ebay_edit_pricing_and_quantity.auto_decline_price)
            ? 1
            : 0;

        data.auto_accept_price = data.best_offer
          ? +state.ebay_edit_pricing_and_quantity.auto_accept_price
          : 0;
        data.auto_decline_price = data.best_offer
          ? +state.ebay_edit_pricing_and_quantity.auto_decline_price
          : 0;
      }

      api
        .updateEbayListing(id, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCollections({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getCollections()
        .then((response) => {
          commit(types.SET_COLLECTIONS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  linkToProduct({ commit }, { listingId, product_id }) {
    return new Promise((resolve, reject) => {
      api
        .linkToProduct({ listingId, product_id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  linkToProductEbay({ commit }, { listingId, product_id }) {
    return new Promise((resolve, reject) => {
      api
        .linkToProductEbay({ listingId, product_id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeEbayChannelActiveStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .changeEbayChannelActiveStatus(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeShopifyChannelActiveStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .changeShopifyChannelActiveStatus(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeBackmarketChannelActiveStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .changeBackmarketChannelActiveStatus(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  linkToProductWalmart({ commit }, { listingId, product_id }) {
    return new Promise((resolve, reject) => {
      api
        .linkToProductWalmart({ listingId, product_id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  linkToProductBackmarket({ commit }, { listingId, product_id }) {
    return new Promise((resolve, reject) => {
      api
        .linkToProductBackmarket({ listingId, product_id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  publishListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .publishListing(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  backmarketPublishListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .backmarketPublishListing(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  publishWalmartListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .publishWalmartListing(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  ebayPublishListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .ebayPublishListing(id)
        .then((response) => {
          commit(types.SET_EDIT_EBAY_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEbayListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteEbayListing(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  syncEbayListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .syncEbayListing(id)
        .then((response) => {
          commit(types.SET_EDIT_EBAY_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  endEbayListing({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .endEbayListing(payload)
        .then((response) => {
          commit(types.SET_EDIT_EBAY_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  syncEbayAllListings({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .syncEbayAllListings()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteWalmartListing({ commit }, ids) {
    return new Promise((resolve, reject) => {
      let idsFormData = new FormData();
      ids.forEach((item) => {
        idsFormData.append("ids[]", item);
      });
      api
        .deleteWalmartListing(idsFormData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteBackmarketListing({ commit }, ids) {
    return new Promise((resolve, reject) => {
      let idsFormData = new FormData();
      ids.forEach((item) => {
        idsFormData.append("ids[]", item);
      });
      api
        .deleteBackmarketListing(idsFormData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unlinkFromProduct({ commit }, listingId) {
    return new Promise((resolve, reject) => {
      api
        .unlinkFromProduct(listingId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unlinkFromProductEbay({ commit }, listingId) {
    return new Promise((resolve, reject) => {
      api
        .unlinkFromProductEbay(listingId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unlinkFromProductWalmart({ commit }, listingId) {
    return new Promise((resolve, reject) => {
      api
        .unlinkFromProductWalmart(listingId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unlinkFromProductBackmarket({ commit }, listingId) {
    return new Promise((resolve, reject) => {
      api
        .unlinkFromProductBackmarket(listingId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteListing({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .deleteListing(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEbayListings({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteEbayListings(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchForLink({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .searchForLink(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  syncListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .syncListing(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  syncListingFromBackmarket({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .syncListingFromBackmarket(id)
        .then((response) => {
          commit(types.SET_EDIT_BACKMARKET_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  syncWalmartListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .syncWalmartListing(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  shopifyListingImport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .shopifyListingImport(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  ebayListingImport({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .importEbayListing(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  importListingFromWalmart({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .importListingFromWalmart(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  importListingFromBackmarket({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .importListingFromBackmarket(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  syncCollections({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .syncCollections()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  bulkPublishShopify({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .bulkPublishShopify(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEbayListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getEbayListing(id)
        .then((response) => {
          commit(types.SET_EDIT_EBAY_LISTINGS, response.data.data.listing);
          commit(types.SET_EDIT_EBAY_CONDITIONS, response.data.data.conditions);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getWalmartListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getWalmartListing(id)
        .then((response) => {
          commit(types.SET_EDIT_WALMART_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBackmarketListing({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getBackmarketListing(id)
        .then((response) => {
          commit(types.SET_EDIT_BACKMARKET_LISTINGS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchCategories({ commit }, search_text) {
    return new Promise((resolve, reject) => {
      api
        .searchEbayCategories(search_text)
        .then((response) => {
          commit(types.SET_SUGG_CATEGORIES, response.data.categorySuggestions);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  aspectsCategories({ commit }, category) {
    commit(types.SET_ASPECTS, []);
    return new Promise((resolve, reject) => {
      api
        .aspectsEbayCategories(category)
        .then((response) => {
          commit(types.SET_ASPECTS, response.data.aspects);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEbayConditions({ commit }, category) {
    return new Promise((resolve, reject) => {
      api
        .getEbayConditions(category)
        .then((response) => {
          commit(types.SET_EDIT_EBAY_CONDITIONS, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBrowseCategories({ commit }, queryObject) {
    return new Promise((resolve, reject) => {
      api
        .getBrowseCategories(queryObject)
        .then((response) => {
          commit(types.SET_EBAY_BROWSE_CATEGORIES, response.data?.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  [types.SET_SHOPIFY_LISTINGS](state, listings) {
    let listings_arr = [];
    let data = listings.data;
    if (data) {
      for (const key in data) {
        data[key].selected = false;
        listings_arr.push(data[key]);
      }
    }

    state.shopifyListings = listings;
    state.shopifyListings.data = listings_arr;
  },
  [types.SET_COLLECTIONS](state, collections) {
    state.listing_collections = collections;
  },
  [types.SET_EDIT_SHOPIFY_LISTINGS](state, listing) {
    state.shopifyEditListing = listing;
  },
  [types.SET_WALMART_LISTINGS](state, listings) {
    // let listings_arr = [];
    // for (let i = 0; i < listings.data.length; i++) {
    //   listings[i].selected = false;
    //   listings_arr.push(listings[i]);
    // }
    // state.ebayListings = listings_arr;
    state.walmartListings = listings;
  },
  [types.SET_BACKMARKET_LISTINGS](state, listings) {
    // let listings_arr = [];
    // for (let i = 0; i < listings.data.length; i++) {
    //   listings[i].selected = false;
    //   listings_arr.push(listings[i]);
    // }
    // state.ebayListings = listings_arr;
    state.backmarketListings = listings;
  },
  [types.SET_EBAY_LISTINGS](state, listings) {
    // let listings_arr = [];
    // for (let i = 0; i < listings.data.length; i++) {
    //   listings[i].selected = false;
    //   listings_arr.push(listings[i]);
    // }
    // state.ebayListings = listings_arr;
    state.ebayListings = listings;
  },
  [types.SET_EDIT_EBAY_LISTINGS](state, listing) {
    state.ebayEditListing = listing;
  },
  [types.SET_EDIT_WALMART_LISTINGS](state, listing) {
    state.walmartEditListing = listing;
  },
  [types.SET_EDIT_BACKMARKET_LISTINGS](state, listing) {
    state.backmarketEditListings = listing;
  },
  [types.SET_EDIT_EBAY_CONDITIONS](state, condition) {
    state.ebayEditConditions = condition[0];
  },
  [types.SET_EDIT_EBAY_LOCATION](state, location) {
    state.ebay_edit_listing_location = {
      ...state.ebay_edit_listing_location,
      ...location,
    };
  },
  [types.SET_EDIT_EBAY_TAX_INFO](state, tax_info) {
    state.ebay_edit_listing_tax_info = tax_info;
  },
  [types.SET_EBAY_BROWSE_CATEGORIES](state, browse_categories) {
    state.ebay_browse_categories = browse_categories;
  },

  [types.SET_EBAY_LISTING_CHECKED](state, { event, value }) {
    const listings = [...state.ebayListings.data];
    state.ebayListings.data = [];

    let find = listings.find((a) => a.id == event.id);
    if (find) {
      find.selected = value;
    }

    state.ebayListings.data = listings;
  },
  [types.SET_BACKMARKET_LISTING_CHECKED](state, { event, value }) {
    const listings = [...state.backmarketListings.data];
    state.backmarketListings.data = [];

    let find = listings.find((a) => a.id == event.id);
    if (find) {
      find.selected = value;
    }

    state.backmarketListings.data = listings;
  },
  [types.SET_ALL_EBAY_LISTING_CHECKED](state, { event }) {
    const listings = [...state.ebayListings.data];
    state.ebayListings.data = [];
    listings.map((p) => {
      p.selected = event;
      return p;
    });

    state.ebayListings.data = listings;
  },
  [types.SET_ALL_WALMART_LISTING_CHECKED](state, { event }) {
    const listings = [...state.walmartListings.data];
    state.walmartListings.data = [];
    listings.map((p) => {
      p.selected = event;
      return p;
    });

    state.walmartListings.data = listings;
  },
  [types.SET_ALL_BACKMARKET_LISTING_CHECKED](state, { event }) {
    const listings = [...state.backmarketListings.data];
    state.backmarketListings.data = [];
    listings.map((p) => {
      p.selected = event;
      return p;
    });

    state.backmarketListings.data = listings;
  },

  [types.SET_LISTING_CHECKED](state, { event, value }) {
    const listings = [...state.shopifyListings.data];
    state.shopifyListings.data = [];

    let find = listings.find((a) => a.id == event.id);
    if (find) {
      find.selected = value;
    }

    state.shopifyListings.data = listings;
  },
  [types.SET_ALL_LISTING_CHECKED](state, { event }) {
    const listings = [...state.shopifyListings.data];
    state.shopifyListings.data = [];
    listings.map((p) => {
      p.selected = event;
      return p;
    });

    state.shopifyListings.data = listings;
  },
  [types.SET_COLLECTIONS](state, collections) {
    state.listing_collections = collections;
  },
  [types.SET_EDIT_SHOPIFY_LISTINGS](state, listing) {
    state.shopifyEditListing = listing;
  },
  [types.SET_SHOPIFY_LISTING_PARAMS](state, params) {
    state.shopifyListingParams = params;
  },
  [types.CLEAR_SHOPIFY_LISTINGS](state) {
    state.shopifyEditListing = null;
  },
  [types.SET_SUGG_CATEGORIES](state, category) {
    state.suggested_categories = category;
  },
  [types.SET_ASPECTS](state, aspects) {
    state.aspects = aspects;
  },
  [types.SET_EBAY_CONDITIONS](state, conditions) {
    state.ebay_listing_conditions = conditions;
  },
  [types.SET_EBAY_LISTING_FORMAT](state, format) {
    state.ebay_listing_format = format;
  },
  [types.SET_EBAY_LISTING_IMMEDIATE_PAYMENT](state, immediate_payment) {
    state.ebay_edit_listing_immediate_payment = immediate_payment;
  },
  [types.SET_EBAY_TITLE_AND_DESCRIPTION](state, title_desc) {
    state.ebay_edit_title_and_description = title_desc;
  },
  [types.SET_EBAY_CATEGORY_AND_FORMAT](state, category_format) {
    state.ebay_edit_category_and_format = {
      ...state.ebay_edit_category_and_format,
      ...category_format,
    };
  },
  [types.SET_EBAY_PRICING_AND_QUANTITY](state, price_quantity) {
    state.ebay_edit_pricing_and_quantity = price_quantity;
  },
  [types.SET_EBAY_DOMESTIC_SHIPPING](state, domestic_shipping) {
    state.ebay_edit_domestic_shipping = {
      ...state.ebay_edit_domestic_shipping,
      ...domestic_shipping,
    };
  },
  [types.SET_EBAY_HANDLING_TIME](state, handling_time) {
    state.ebay_edit_handling_time = handling_time;
  },
  [types.SET_EBAY_EXCLUDE_LOCATIONS](state, exclude_locations) {
    state.ebay_edit_exclude_locations = exclude_locations;
  },
  [types.SET_EBAY_SCHEDULED_TIME](state, time) {
    state.ebay_edit_listing_scheduled_time = time;
  },
  [types.SET_EBAY_PACKAGE_DETAILS](state, package_details) {
    state.ebay_edit_package_details = {
      ...state.ebay_edit_package_details,
      ...package_details,
    };
  },
  [types.SET_EBAY_INTERNATIONAL_SHIPPING](state, international_shipping) {
    state.ebay_edit_international = {
      ...state.ebay_edit_international,
      ...international_shipping,
    };
  },
  [types.SET_EBAY_RETURN_POLICY](state, return_policy) {
    state.return_policy = {
      ...state.return_policy,
      ...return_policy,
    };
  },
  [types.SET_EBAY_GLOBAL_SHIPPING](state, global_shipping) {
    state.ebay_edit_global_shipping = global_shipping;
  },
  [types.SET_EBAY_PRODUCT_IDENTIFIERS](state, product_identifiers) {
    state.ebay_edit_product_identifiers = product_identifiers;
  },
  [types.SET_EBAY_PRODUCT_VARIANTS](state, variants) {
    state.ebay_edit_product_variants = variants;
  },
};
const state = {
  ebay_edit_data: {},
  shopifyListings: {
    data: [],
  },
  suggested_categories: [],
  aspects: [],
  ebay_listing_conditions: {
    conditions: [],
  },
  listing_collections: [],
  shopifyEditListing: null,
  shopifyListingParams: null,
  ebayEditListing: [],
  walmartEditListing: [],
  ebayEditConditions: [],
  ebayListings: [],
  walmartListings: [],
  selected_ebay_listings: [],
  backmarketEditListings: [],
  backmarketListings: [],
  ebay_listing_format: null,
  product_identifiers: [
    {
      id: 1,
      img: "xiaomi-11-ultra.jpg",
      link: "link",
      status: "",
      title: "Xiaomi MI 11 Ultra",
      sku: "MI-0032",
      // mpn: null,
      // upc: null,
      // ean: null,
      // isbn: null,
      product_type: "Phone",
      quantity: "3",
      price: "400",
      selected: true,
    },
  ],
  identifier_options: [
    { key: "sku", label: "SKU" },
    { key: "mpn", label: "MPN" },
    { key: "upc", label: "UPC" },
    { key: "ean", label: "EAN" },
    { key: "isbn", label: "ISBN" },
  ],
  products: [],
  ebay_edit_title_and_description: {},
  ebay_edit_category_and_format: {},
  ebay_edit_pricing_and_quantity: {},
  ebay_edit_domestic_shipping: {},
  ebay_edit_handling_time: "",
  ebay_edit_exclude_locations: [],
  ebay_edit_international: {
    additional_locations: [],
  },
  ebay_edit_package_details: {},
  ebay_edit_listing_location: {},
  ebay_edit_listing_tax_info: {
    tax_table: true,
  },
  ebay_edit_listing_scheduled_time: "",
  ebay_edit_listing_immediate_payment: false,
  ebay_edit_global_shipping: false,
  return_policy: {},
  ebay_edit_product_identifiers: {},
  ebay_edit_product_variants: [],
  ebay_browse_categories: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
