import Vue from "vue";
import Router from "vue-router";
import routes from "@/router/routes";
// import { store } from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [{ path: "/", redirect: "/dashboard" }, ...routes]
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (!token && to.name === "login") {
    next();
  } else {
    if (to.name !== "login" && token) next();
    else {
      if(!token){
        next("login")
      }
    }
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "IsWorthMore";
  });
});


export default router;
