/* eslint-disable */
import * as types from "@/store/mutation-types";
import api from "@/services/api/settings";

const getters = {
  settingNavigation: (state) => state.settingNavigation,
  salesChannels: (state) => state.salesChannels,
  accountInfo: (state) => state.accountInfo,
  ebayConnectCountryVariants: (state) => state.ebayConnectCountryVariants,
  settingsProductCategories: (state) => state.settingsProductCategories,
  settingsProductAttributes: (state) => state.settingsProductAttributes
};

const actions = {
  addAttributes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .addCustomAttributes(payload)
        .then((response) => {
          commit(types.ADD_CUSTOM_ATTRIBUTES, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editAttribute({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editAttribute(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeAttribute({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeAttribute(payload)
        .then((response) => {
          commit(types.DELETE_CUSTOM_ATTRIBUTE, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSettingsCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCategories(payload)
        .then((response) => {
          commit(types.SET_CATEGORIESS, response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addSettingsCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .addCategory(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editSettingsCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editCategory(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeSettingsCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .removeCategory(payload)
        .then((response) => {
          commit(types.DELETE_CATEGORY, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCredentials({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateCredentials(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCredentials({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getCredentials(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAccountInfo({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAccountInfo()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAccountInfo({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .updateAccountInfo(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadProductsCSV({ commit }, file) {
    return new Promise((resolve, reject) => {
      api
        .importProductsCSV(file)
        .then((response) => resolve(response))
        .catch(err => reject(err));
    });
  },
  uploadInventoryCSV({ commit }, file) {
    return new Promise((resolve, reject) => {
      api
        .importInventoryCSV(file)
        .then((response) => resolve(response))
        .catch(err => reject(err));
    });
  },

};

const mutations = {
  [types.SET_CATEGORIESS](state, categories) {
    state.settingsProductCategories = categories;
  },
  [types.DELETE_CATEGORY](state, id) {
    const index = state.settingsProductCategories.findIndex((a) => a.id === id);
    state.settingsProductCategories.splice(index, 1);
  },
  [types.DELETE_CUSTOM_ATTRIBUTE](state, id) {
    const index = state.settingsProductAttributes.findIndex((a) => a.id === id);
    state.settingsProductAttributes.splice(index, 1);
  },

  [types.SET_ATTRIBUTES](state, attributes) {
    state.settingsProductAttributes = attributes;
  },
  [types.ADD_CUSTOM_ATTRIBUTES](state, newAttr) {
    state.settingsProductAttributes.push(newAttr);
  }
};

const state = {
  settingNavigation: [
    {
      name: "ACCOUNT SETTINGS",
      navigationItems: [
        { key: "account_info", text: "Account Info" },
        { key: "change_password", text: "Change Password" },
        { key: "users", text: "Users" }
      ]
    },
    {
      name: "BILLINGS SETTINGS",
      navigationItems: [
        { key: "payment_info", text: "Payment Info" },
        { key: "your_subscription", text: "Your Subscription" },
        { key: "postage_wallet", text: "Postage Wallet" }
      ]
    },
    {
      name: "APPLICATION SETTINGS",
      navigationItems: [
        { key: "channels", text: "Sales Channel" },
        { key: "app_store", text: "App Store" },
        { key: "shipping_carriers", text: "Shipping Carriers" },
        { key: "warehouse_location", text: "Warehouse Location" },
        { key: "listing_presets", text: "Listing Presets" },
        { key: "export_data", text: "Export Data" },
        { key: "import_data", text: "Import Data" },
        { key: "product_categories", text: "Product Categories" },
        { key: "custom_product_attributes", text: "Custom Product Attributes" },
        {
          key: "listing_templates_recipes",
          text: "Listing Templates & Recipes"
        }
      ]
    }
  ],
  salesChannels: [
    {
      key: "channel1",
      name: "Ebay",
      img: "Ebay.svg",
      link: "/settings/channels/ebay/add_key"
    },
    {
      key: "channel2",
      name: "Shopify",
      img: "shopify.svg",
      link: "/settings/channels/shopify/add_key"
    },
    {
      key: "channel3",
      name: "Walmart",
      img: "walmart-logo.png",
      link: "/settings/channels/walmart/add_key"
    },
    {
      key: "channel4",
      name: "Backmarket",
      img: "backmarket.png",
      link: "/settings/channels/backmarket/add_key"
    }
  ],
  accountInfo: [
    {
      accountId: "user1",
      info: [
        { key: "first_name", name: "First Name", value: "Arshak" },
        { key: "last_name", name: "Last Name", value: "Khazaryan" },
        { key: "email", name: "Email", value: "khazaryan@gmail.com" },
        { key: "tel", name: "Telephone", value: "37455555555" },
        { key: "company_name", name: "Company Name", value: "Company Name" },
        { key: "user_access", name: "user Access", value: "All access" }
      ]
    }
  ],
  ebayConnectCountryVariants: [
    { key: "eBay.com", text: "eBay.com (United States)" },
    { key: "eBay.ca", text: "eBay.ca (Canada - English)" },
    { key: "cafr.eBay.ca", text: "cafr.eBay.ca (Canada - French)" },
    { key: "eBay.co.uk", text: "eBay.co.uk (United Kingdom)" },
    { key: "eBay.com.au", text: "eBay.com.au (Australia)" },
    { key: "eBay.at", text: "eBay.at (Austria)" },
    { key: "benl.eBay.be", text: "benl.eBay.be (Belgium - Dutch)" },
    { key: "befr.eBay.be", text: "befr.eBay.be (Belgium - French)" },
    { key: "eBay.fr", text: "eBay.fr (France)" },
    { key: "eBay.de", text: "eBay.de (Germany)" },
    { key: "eBay.ie", text: "eBay.ie (Ireland)" },
    { key: "eBay.it", text: "eBay.it (Italy)" },
    { key: "eBay.nl", text: "eBay.nl (Netherlands)" },
    { key: "eBay.pl", text: "eBay.pl (Poland)" },
    { key: "eBay.es", text: "eBay.es (Spain)" },
    { key: "eBay.ch", text: "eBay.ch (Switzerland)" }
  ],
  settingsProductCategories: [],
  settingsProductAttributes: []
};

export default {
  state,
  getters,
  actions,
  mutations
};
