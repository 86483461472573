import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import { store } from "@/store";
import "@/plugins/axios";
import "@/plugins/moment";
import '@/plugins/vueTailwind';
import '@/plugins/vueColumnsResize';
import i18n from "@/plugins/i18n";
import '@/components/index';
import '@/assets/scss/tailwind.css';
import '@/assets/scss/general.scss';
import Multiselect from 'vue-multiselect'
import Scrollspy from 'vue2-scrollspy';
import "vue-multiselect/dist/vue-multiselect.min.css"
import Selectize from "vue2-selectize";
import ZoomOnHover from "vue-zoom-on-hover";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Notifications from 'vue-notification'
import Paginate from "vuejs-paginate";
import VueLoading from 'vuejs-loading-plugin'
import Loader from '@/components/ui/Loader'
Vue.component("paginate", Paginate);

// register globally
Vue.component('multiselect', Multiselect)
Vue.component('selectize', Selectize)
Vue.component('zoom-on-hover',ZoomOnHover)
Vue.config.productionTip = false;
Vue.use(Scrollspy);
Vue.use(Notifications);
Vue.use(ZoomOnHover);
Vue.use(CKEditor)
Vue.use(VueLoading, {
  customLoader: Loader
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created(){
    store.commit('SET_LOCALE', store.getters.locale);
  }
}).$mount("#app");
