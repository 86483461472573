/* eslint-disable */
import * as types from '@/store/mutation-types'
import i18n from '@/plugins/i18n'

const getters = {
  locale: (state) => state.locale,
}

const mutations = {
  [types.SET_LOCALE](state, value) {
    i18n.locale = value
    state.locale = value
    window.localStorage.setItem('locale', value)
  }
}

const state = {
  locale:
    localStorage.getItem('locale') || "en"
}

export default {
  state,
  getters,
  mutations
}
