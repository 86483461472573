const international_services = [
    {

        "name": "ECONOMY SERVICES",
        options: [
            {
                "key": "USPSFirstClassMailInternational",
                "text": "USPS First Class Mail Intl / First Class Package Intl Service"
            },
            {
                "key": "USPSPriorityMailInternational",
                "text": "USPS Priority Mail International (6 to 25 business days)"
            },
            {
                "key": "USPSPriorityMailInternationalFlatRateEnvelope",
                "text": "USPS Priority Mail International Flat Rate Envelope (6 to 25 business days)"
            },
            {
                "key": "USPSPriorityMailInternationalSmallFlatRateBox",
                "text": "USPS Priority Mail International Small Flat Rate Box (6 to 25 business days)"
            },
            {
                "key": "USPSPriorityMailInternationalFlatRateBox",
                "text": "USPS Priority Mail International Medium Flat Rate Box (6 to 25 business days)"
            },
            {
                "key": "USPSPriorityMailInternationalLargeFlatRateBox",
                "text": "USPS Priority Mail International Large Flat Rate Box (6 to 25 business days)"
            },
            {
                "key": "USPSPriorityMailInternationalPaddedFlatRateEnvelope",
                "text": "USPS Priority Mail International Padded Flat Rate Envelope (6 to 25 business days)"
            },
            {
                "key": "USPSPriorityMailInternationalLegalFlatRateEnvelope",
                "text": "USPS Priority Mail International Legal Flat Rate Envelope (6 to 25 business days)"
            },
            {
                "key": "USPSFirstClassMailInternationalLetter",
                "text": "USPS First Class Mail International Letter"
            },
            {
                "key": "USPSFirstClassMailInternationalLargeEnvelope",
                "text": "USPS First Class Mail International Large Envelope"
            },
            {
                "key": "USPSFirstClassMailInternationalParcel",
                "text": "USPS First Class Mail International Parcel"
            },
            {
                "key": "UPSStandardToCanada",
                "text": "UPS Standard to Canada"
            },
        ]
    },
    {
        "name": "STANDARD SERVICES",
        options: [
            {
                "key": "USPSExpressMailInternational",
                "text": "USPS Priority Mail Express International (3 to 5 business days)"
            },
            {
                "key": "USPSExpressMailInternationalFlatRateEnvelope",
                "text": "USPS Priority Mail Express International Flat Rate Envelope (3 to 5 business days)"
            },
            {
                "key": "USPSExpressMailInternationalLegalFlatRateEnvelope",
                "text": "USPS Priority Mail Express International Legal Flat Rate Envelope (3 to 5 business days)"
            },
            {
                "key": "UPSWorldWideExpedited",
                "text": "UPS Worldwide Expedited (2 to 5 business days)"
            }
        ]
    },
    {
        "name": "EXPEDITED SERVICES",
        options: [
            {
                "key": "UPSWorldWideExpressPlus",
                "text": "UPS Worldwide Express Plus (1 to 2 business days)"
            },
            {
                "key": "UPSWorldWideExpress",
                "text": "UPS Worldwide Express (1 to 2 business days)"
            },
            {
                "key": "UPSWorldwideSaver",
                "text": "UPS Worldwide Saver (1 to 3 business days)"
            }
        ]
    }


]

export default international_services;