<template>
  <div id="app">
    <router-view />
    <notifications position="top right" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
  },
  computed: {
    ...mapGetters(['loader'])
  },
};
</script>
<style>
</style>